import { type FC } from 'react';
import { Navigate, type RouteProps } from 'react-router-dom';
import { authProvider } from 'providers/authProvider';

const Private: FC<RouteProps> = ({ element }) => {
  return authProvider.isAuthenticated ? (
    element
  ) : (
    <Navigate to="/users/sign_in" replace />
  );
};

export default Private;
